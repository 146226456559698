<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footerList">
        <div class="footerItem">
          <h4>购物指南</h4>
          <ul class="footerItemCon">
            <li>购物流程</li>
            <li>会员介绍</li>
            <li>生活旅行/团购</li>
            <li>常见问题</li>
            <li>购物指南</li>
          </ul>
        </div>
        <div class="footerItem">
          <h4>配送方式</h4>
          <ul class="footerItemCon">
            <li>上门自提</li>
            <li>211限时达</li>
            <li>配送服务查询</li>
            <li>配送费收取标准</li>
            <li>海外配送</li>
          </ul>
        </div>
        <div class="footerItem">
          <h4>支付方式</h4>
          <ul class="footerItemCon">
            <li>货到付款</li>
            <li>在线支付</li>
            <li>分期付款</li>
            <li>邮局汇款</li>
            <li>公司转账</li>
          </ul>
        </div>
        <div class="footerItem">
          <h4>售后服务</h4>
          <ul class="footerItemCon">
            <li>售后政策</li>
            <li>价格保护</li>
            <li>退款说明</li>
            <li>返修/退换货</li>
            <li>取消订单</li>
          </ul>
        </div>
        <div class="footerItem">
          <h4>特色服务</h4>
          <ul class="footerItemCon">
            <li>夺宝岛</li>
            <li>DIY装机</li>
            <li>延保服务</li>
            <li>邦邦商城E卡</li>
            <li>邦邦商城通信</li>
          </ul>
        </div>
        <div class="footerItem">
          <h4>微信公众号</h4>
          <!-- <img class="qrcode" src="@/assets/wx_cz.jpg" /> -->
          <el-image style="width: 100px; height: 100px" :src="url" fit="cover"></el-image>
        </div>
      </div>
      <div class="copyright">
        <p>Copyright©商邦科技 业务咨询及服务热线：010-53325698</p>
        <p><a href="http://beian.miit.gov.cn/">京ICP备2024042191号</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'
    }
  }
}
</script>

<style lang="less">
.footer {
  //background-color: #eaeaea;
  background-color: #ad2e27;
  color: #fff;

  .footer-container {
    width: 1200px;
    margin: 0 auto;
    padding: 0 15px;

    .footerList {
      padding: 20px;
      border-bottom: 1px solid #e4e1e1;
      overflow: hidden;
      padding-left: 40px;

      .footerItem {
        width: 16.6666667%;
        float: left;

        .qrcode {
          width: 100px;
          height: 110px;
          object-fit: cover;
          object-position: top;
        }

        h4 {
          font-size: 18px;
          margin: 20px 0;
        }

        .footerItemCon {
          li {
            line-height: 2;
            font-size: 14px;
          }
        }

        &:last-child img {
          width: 121px;
        }
      }
    }

    .copyright {
      padding: 20px;
      font-size: 14px;

      .helpLink {
        text-align: center;

        li {
          display: inline;

          .space {
            border-left: 1px solid #666;
            width: 1px;
            height: 13px;
            background: #666;
            margin: 8px 10px;
          }
        }
      }

      p {
        margin: 10px 0;
        text-align: center;

        a {
          color: #999;
        }
      }
    }
  }
}
</style>
