import {
  getTradeInfo,
  postOrderSubmit,
  postOrderUpdate,
  getCouponByProd,
} from '@/api'

export default {
  namespaced: true,
  state: {
    tradeInfo: {},
    orderSubmit: {},
  },
  mutations: {
    TRADEINFO(state, tradeInfo) {
      state.tradeInfo = tradeInfo
    },
    ORDERSUBMIT(state, orderSubmit) {
      state.orderSubmit = orderSubmit
    },
  },
  actions: {
    async getTradeInfo({ commit }) {
      const res = await getTradeInfo()
      if (res.code === 200) {
        commit('TRADEINFO', res.data)
      }
    },
    async postOrderSubmit({ commit }, params) {
      const res = await postOrderSubmit(params)
      if (res.code === '00000') {
        commit('ORDERSUBMIT', res.data)
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async postOrderUpdate(_, params) {
      const res = await postOrderUpdate(params)
      if (res.code === '00000') {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    // 判断商品是否有匹配的优惠券
  },
  getters: {
    detailArrayList(state) {
      return state.tradeInfo.detailArrayList || []
    },
  },
}
