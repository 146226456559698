var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"footerList"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"footerItem"},[_c('h4',[_vm._v("微信公众号")]),_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":_vm.url,"fit":"cover"}})],1)]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('h4',[_vm._v("购物指南")]),_c('ul',{staticClass:"footerItemCon"},[_c('li',[_vm._v("购物流程")]),_c('li',[_vm._v("会员介绍")]),_c('li',[_vm._v("生活旅行/团购")]),_c('li',[_vm._v("常见问题")]),_c('li',[_vm._v("购物指南")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('h4',[_vm._v("配送方式")]),_c('ul',{staticClass:"footerItemCon"},[_c('li',[_vm._v("上门自提")]),_c('li',[_vm._v("211限时达")]),_c('li',[_vm._v("配送服务查询")]),_c('li',[_vm._v("配送费收取标准")]),_c('li',[_vm._v("海外配送")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('h4',[_vm._v("支付方式")]),_c('ul',{staticClass:"footerItemCon"},[_c('li',[_vm._v("货到付款")]),_c('li',[_vm._v("在线支付")]),_c('li',[_vm._v("分期付款")]),_c('li',[_vm._v("邮局汇款")]),_c('li',[_vm._v("公司转账")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('h4',[_vm._v("售后服务")]),_c('ul',{staticClass:"footerItemCon"},[_c('li',[_vm._v("售后政策")]),_c('li',[_vm._v("价格保护")]),_c('li',[_vm._v("退款说明")]),_c('li',[_vm._v("返修/退换货")]),_c('li',[_vm._v("取消订单")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('h4',[_vm._v("特色服务")]),_c('ul',{staticClass:"footerItemCon"},[_c('li',[_vm._v("夺宝岛")]),_c('li',[_vm._v("DIY装机")]),_c('li',[_vm._v("延保服务")]),_c('li',[_vm._v("邦邦商城E卡")]),_c('li',[_vm._v("邦邦商城通信")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v("Copyright©商邦科技 业务咨询及服务热线：010-53325698")]),_c('p',[_c('a',{attrs:{"href":"http://beian.miit.gov.cn/"}},[_vm._v("京ICP备2024042191号")])])])
}]

export { render, staticRenderFns }