import {
  getInvoice,
  updateInvoice,
  addInvoice,
  getInvoiceOrder,
  postApplyInvoice,
} from '@/api'
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    invoiceInfo: [],
    invoiceList: [],
  },
  mutations: {
    INVOICEINFO(state, invoiceInfo) {
      state.invoiceInfo = invoiceInfo
    },
    INVOICELIST(state, invoiceList) {
      state.invoiceList = null
      state.invoiceList = invoiceList
    },
  },
  actions: {
    async getInvoice({ commit }) {
      const res = await getInvoice()
      if (res.code === '00000') {
        commit('INVOICEINFO', res.data)
      }
    },
    async updateInvoice(_, params) {
      const res = await updateInvoice(params)
      if (res.code === '00000') {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async addInvoice(_, params) {
      const res = await addInvoice(params)
      if (res.code === '00000') {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async getInvoiceList({ commit }, params) {
      const res = await getInvoiceOrder(params)
      if (res.code === '00000') {
        commit('INVOICELIST', res.data.records)
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async postApplyInvoice(_, params) {
      const res = await postApplyInvoice(params)
      if (res.code === '00000') {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
  },
  getters: {
    invoiceList: (state) => state.invoiceList.records,
    total: (state) => state.invoiceList.total,
  },
}
