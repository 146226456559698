import { v4 as uuid } from 'uuid'
const isEmpty = (obj) => {
  if (typeof obj === 'undefined' || obj === null || obj === '') {
    return true
  } else {
    return false
  }
}
// 移除对象中空元素
export const removeEmptyElement = (obj) => {
  Object.keys(obj).forEach((item) => {
    if (isEmpty(obj[item])) {
      delete obj[item]
    }
  })
  return obj
}

// 获取游客 uuid
export const getUUID = () => {
  let uuid_token = localStorage.getItem('uuid_token')
  if (!uuid_token) {
    uuid_token = uuid()
    localStorage.setItem('uuid_token', uuid_token)
  }
  return uuid_token
}

export const setToken = (token) => {
  localStorage.setItem('token', token)
}

export const setRefreshToken = (refreshToken) => {
  localStorage.setItem('refresh-token', refreshToken)
}

export const getToken = () => {
  return localStorage.getItem('token')
}

export const getRefreshToken = () => {
  return localStorage.getItem('refresh-token')
}

export const removeToken = () => {
  localStorage.removeItem('token')
}

export const removeRefreshToken = () => {
  localStorage.removeItem('refresh-token')
}

export const clearStorage = () => {
  localStorage.clear()
}

export const errorHandler = async (error) => {
  let errorMessage = error.message
  try {
    const body = await error.response.text()
    const result = JSON.parse(body)

    if (result.error) {
      errorMessage = result.error
    }
  } catch {}

  return errorMessage
}

// 将数字处理为金额
export const toMoney = (num) => {
  num = num.toFixed(2)
  num = parseFloat(num)
  num = num.toLocaleString()
  return num
}

/**
 * 构造树型结构数据
 *
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(
  data,
  id = 'id',
  parentId = 'parentId',
  children = 'children',
  rootId = 0
) {
  // 对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data))
  // 循环所有项
  const treeData = cloneData.filter((father) => {
    let branchArr = cloneData.filter((child) => {
      //返回每一项的子级数组
      return father[id] === child[parentId]
    })
    branchArr.length > 0 ? (father.children = branchArr) : ''
    //返回第一层
    return father[parentId] === rootId
  })
  return treeData !== '' ? treeData : data
}

export function paramsToQuery(params) {
  if (isEmpty(params)) {
    return ''
  }
  // return new URLSearchParams(Object.entries(params)).toString();
  let query = []
  for (let key in params) {
    query.push(key + '=' + params[key])
  }

  return query.join('&')
}

/**
 * 将分转成元
 *
 * @param price 分，例如说 100 分
 * @returns {string} 元，例如说 1.00 元
 */
export function fen2yuan(price) {
  return (price / 100.0).toFixed(2)
}

/**
 * 从商品 SKU 数组中，转换出商品属性的数组
 *
 * 类似结构：[{
 *    id: // 属性的编号
 *    name: // 属性的名字
 *    values: [{
 *      id: // 属性值的编号
 *      name: // 属性值的名字
 *    }]
 * }]
 *
 * @param skus 商品 SKU 数组
 */
export function convertProductPropertyList(skus) {
  let result = []
  for (const sku of skus) {
    if (!sku.properties) {
      continue
    }
    for (const property of sku.properties) {
      // ① 先处理属性
      let resultProperty = result.find(
        (item) => item.id === property.propertyId
      )
      if (!resultProperty) {
        resultProperty = {
          id: property.propertyId,
          name: property.propertyName,
          values: [],
        }
        result.push(resultProperty)
      }
      // ② 再处理属性值
      let resultValue = resultProperty.values.find(
        (item) => item.id === property.valueId
      )
      if (!resultValue) {
        resultProperty.values.push({
          id: property.valueId,
          name: property.valueName,
        })
      }
    }
  }
  return result
}

/**
 * 格式化订单状态
 *
 * @param order 订单
 */
export function formatOrderStatus(order) {
  if (order.status === 0) {
    return '待付款'
  }
  if (order.status === 10 && order.deliveryType === 1) {
    return '待发货'
  }
  if (order.status === 10 && order.deliveryType === 2) {
    return '待发货'
  }
  if (order.status === 20) {
    return '待收货'
  }
  if (order.status === 30 && !order.commentStatus) {
    return '待评价'
  }
  if (order.status === 30 && order.commentStatus) {
    return '已完成'
  }
  return '已关闭'
}

/**
 * 将一个整数转换为分数保留两位小数
 * @param num
 */
export const formatToFraction = (num) => {
  if (typeof num === 'undefined') return '0.00'
  const parsedNumber = typeof num === 'string' ? parseFloat(num) : num
  return (parsedNumber / 100.0).toFixed(2)
}

export const fenToYuan = (price) => {
  return formatToFraction(price)
}
