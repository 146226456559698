import OrderApi from '@/api/trade/order'

export default {
  namespaced: true,
  state: {
    captcha: '',
    orderList: {
      list: [],
      total: 0,
    },
    orderDetail: {
      orderItemDtos: [],
    },
  },
  mutations: {
    SENDCODE(state, captcha) {
      state.captcha = captcha
    },
    ORDERLIST(state, orderList) {
      state.orderList = orderList
    },
    ORDERDETAIL(state, orderDetail) {
      state.orderDetail = orderDetail
    },
  },
  actions: {
    async getOrderList({ commit }, params) {
      const res = await OrderApi.getOrderPage(params)
      console.log('res', res)
      if (res.code === 0) {
        commit('ORDERLIST', res.data)
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    // 获取订单详情
    async getOrderDetail({ commit }, id) {
      const res = await OrderApi.getOrder(id)
      if (res.code === 0) {
        commit('ORDERDETAIL', res.data)
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
  },
  getters: {
    orderList: (state) => {
      return state.orderList.list || []
    },
    total: (state) => state.orderList.total,
  },
}
