import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import { getToken } from '@/utils'

// 使用插件
Vue.use(VueRouter)

const originPush = VueRouter.prototype.push
const originReplace = VueRouter.prototype.replace

// 重写 push
VueRouter.prototype.push = function (params, resolve, reject) {
  if (resolve && reject) {
    originPush.call(this, params, resolve, reject)
  } else {
    originPush.call(
      this,
      params,
      () => {},
      () => {}
    )
  }
}
// 重写 replace
VueRouter.prototype.replace = function (params, resolve, reject) {
  if (resolve && reject) {
    originReplace.call(this, params, resolve, reject)
  } else {
    originReplace.call(
      this,
      params,
      () => {},
      () => {}
    )
  }
}

// 配置路由
const router = new VueRouter({
  routes,
  // 滚动行为
  scrollBehavior() {
    // 路由跳转后自动滚动到顶部
    return { y: 0 }
  },
})

// 全局前置守卫
router.beforeEach(async (to, from, next) => {
  const token = getToken()
  const userInfo = store.state.user.userInfo

  if (token) {
    if (to.name === 'login') {
      next(false)
    } else {
      if (to.name !== 'search') {
        store.commit('home/CURRENTTAG', -1)
      }

      if (!userInfo.nickname) {
        try {
          await store.dispatch('user/getUserInfo')
          next() // 获取用户信息后放行
        } catch (error) {
          await store.dispatch('user/getLogout')
          next('/login')
        }
      } else {
        next() // 用户信息存在则放行
      }
    }
  } else {
    // 如果没有 token
    if (to.meta.requiresAuth) {
      next('/login') // 重定向到 login
    } else {
      next() // 无需登录权限的页面直接放行
    }
  }
})

export default router
