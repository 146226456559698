export default [
  {
    path: '/',
    component: () => import('@/pages/Home'),
    meta: {
      isFooterShow: true,
      requiresAuth: false, // 不需要登录
    },
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/pages/Login'),
    meta: {
      requiresAuth: false, // 不需要登录
    },
  },
  {
    path: '/register',
    component: () => import('@/pages/Register'),
    meta: {
      requiresAuth: false, // 不需要登录
    },
  },
  {
    name: 'search',
    path: '/search/:keyword?',
    component: () => import('@/pages/Search'),
    meta: {
      isFooterShow: true,
      requiresAuth: false, // 不需要登录
    },
    props: ($route) => ({ keyword: $route.params.prodName }),
  },
  {
    name: 'detail',
    path: '/detail/:prodId',
    component: () => import('@/pages/Detail'),
    meta: {
      isFooterShow: true,
      requiresAuth: false, // 不需要登录
    },
    props: ($route) => ({ prodId: $route.params.prodId }),
  },
  {
    name: 'addCartSuccess',
    path: '/addCartSuccess',
    component: () => import('@/pages/AddCartSuccess'),
    meta: {
      isFooterShow: true,
      requiresAuth: true, // 需要登录
    },
  },
  {
    name: 'shopCart',
    path: '/shopCart',
    component: () => import('@/pages/ShopCart'),
    meta: {
      isFooterShow: true,
      requiresAuth: true, // 需要登录
    },
  },
  {
    name: 'trade',
    path: '/trade',
    component: () => import('@/pages/Trade'),
    meta: {
      isFooterShow: true,
      requiresAuth: true, // 需要登录
    },
  },
  {
    name: 'noticeList',
    path: '/noticeList',
    component: () => import('@/pages/NoticeList'),
    meta: {
      isFooterShow: true,
      requiresAuth: true, // 需要登录
    },
  },
  {
    name: 'notice',
    path: '/notice/:id',
    component: () => import('@/pages/NoticeList/detail'),
    meta: {
      isFooterShow: true,
      requiresAuth: true, // 需要登录
    },
    props: ($route) => ({ id: $route.params.id }),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/pages/Mine'),
    meta: {
      isHome: true,
      isFooterShow: true,
      requiresAuth: true, // 需要登录
    },
  },
  {
    path: '/mine',
    component: () => import('@/layouts/MyLayout'),
    meta: { requiresAuth: true }, // MyLayout 及其子路由都需要登录
    children: [
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/pages/Order'),
        meta: {
          isHome: true,
          isFooterShow: true,
          requiresAuth: true,
        },
      },
      {
        path: 'collect',
        name: 'Collect',
        component: () => import('@/pages/Collect'),
        meta: {
          isHome: true,
          isFooterShow: true,
          requiresAuth: true,
        },
      },
      {
        path: 'address',
        name: 'Address',
        component: () => import('@/pages/Address'),
        meta: {
          isHome: true,
          isFooterShow: true,
          requiresAuth: true,
        },
      },
      {
        path: 'invoice',
        name: 'Invoice',
        component: () => import('@/pages/Invoice'),
        meta: {
          isHome: true,
          isFooterShow: true,
          requiresAuth: true,
        },
      },
      {
        path: 'invoiceList',
        name: 'InvoiceList',
        component: () => import('@/pages/InvoiceList'),
        meta: {
          isHome: true,
          isFooterShow: true,
          requiresAuth: true,
        },
      },
    ],
  },
  {
    name: 'orderDetail',
    path: '/orderDetail/:orderNumber',
    component: () => import('@/pages/Order/detail'),
    meta: {
      isHome: true,
      isFooterShow: true,
      requiresAuth: true, // 需要登录
    },
  },
  {
    path: '*',
    redirect: '/',
  },
]
