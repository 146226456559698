import AuthUtil from '@/api/member/auth'
import UserApi from '@/api/member/user'
import {
  removeToken,
  getToken,
  setToken,
  setRefreshToken,
  removeRefreshToken,
} from '@/utils'

export default {
  namespaced: true,
  state: {
    token: '',
    refreshToken: '',
    userInfo: {
      nickname: '',
    },
  },
  mutations: {
    USERINFO(state, userInfo) {
      state.userInfo = userInfo
    },
    LOGOUT(state) {
      state.userInfo = {}
      removeToken()
      removeRefreshToken()
    },
  },
  actions: {
    // 验证码登录
    async postSmsLogin({ commit }, params) {
      const res = await AuthUtil.smsLogin(params)
      if (res.code === 0) {
        setToken(res.data.accessToken)
        setRefreshToken(res.data.refreshToken)
        return 'ok'
      } else {
        return Promise.reject(res.msg)
      }
    },
    async postLogin({ commit }, params) {
      const res = await AuthUtil.login(params)
      if (res.code === '00000') {
        setToken(res.data.accessToken)
        setRefreshToken(res.data.refreshToken)
        return 'ok'
      } else {
        return Promise.reject(res.msg)
      }
    },
    async getUserInfo({ commit }) {
      const res = await UserApi.getUserInfo()
      if (res.code === 0) {
        commit('USERINFO', res.data)
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async getLogout({ commit }) {
      const res = await AuthUtil.logout()
      if (res.code === 0) {
        commit('LOGOUT')
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
  },
  getters: {},
}
