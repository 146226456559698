import { postRegister } from '@/api'

export default {
  namespaced: true,
  state: {
    captcha: '',
  },
  mutations: {
    SENDCODE(state, captcha) {
      state.captcha = captcha
    },
  },
  actions: {
    async postRegister(_, params) {
      const res = await postRegister(params)
      if (res.code === '00000') {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
  },
  getters: {},
}
