<template>
  <header class="header">
    <!-- 头部的第一行 -->
    <div class="top">
      <div class="container">
        <div class="loginList">
          <p>邦邦商城欢迎您！</p>
          <span v-if="name" class="user">
            <span>{{ name }}</span>
            |
            <span @click="handleLogout">退出</span>
          </span>
          <span v-else>
            <span>你好，</span>
            <router-link to="/login" style="color: red">请登录</router-link>
            <router-link to="/login" class="register">免费注册</router-link>
          </span>
        </div>
        <div class="typeList">
          <router-link to="/" v-if="$route.path !== '/'">邦邦首页</router-link>
          <router-link to="/home">个人中心</router-link>
          <router-link to="/mine/order">我的订单</router-link>
          <router-link to="/mine/collect">我的收藏</router-link>
          <router-link to="/shopCart">我的购物车</router-link>
          <!-- <a>我的邦邦商城</a>
          <a>邦邦商城会员</a>
          <a>企业采购</a>
          <a>关注邦邦商城</a>
          <a>合作招商</a>
          <a>商家后台</a> -->
        </div>
      </div>
    </div>
    <!--头部第二行 搜索区域-->
    <div class="bottom">
      <h1 class="logoArea">
        <router-link class="logo" to="/">
          <img src="@/assets/logo.png" alt="" />
        </router-link>
      </h1>
      <div class="searchArea">
        <ul v-if="this.$route.meta.isHome" style="overflow: visible;" class="nav">
          <li>
            <router-link to="/home" exact active-class="active">首页</router-link>
          </li>
          <li>
            <router-link to="/mine/order" exact active-class="active">我的订单</router-link>
          </li>
          <li>
            <router-link to="/mine/collect" exact active-class="active">我的收藏</router-link>
          </li>
          <li>
            <router-link to="/mine/address" exact active-class="active">地址管理</router-link>
          </li>
          <li>
            <router-link to="/mine/invoice" exact active-class="active">发票管理</router-link>
          </li>
        </ul>
        <div class="search" v-else>
          <form class="searchForm" @submit.prevent>
            <input type="text" id="autocomplete" placeholder="请输入关键字" class="input-error input-xxlarge"
              v-model="keyword" @keyup.enter="goSearch" />
            <button class="sui-btn btn-xlarge btn-danger" type="button" @click="goSearch">
              搜索
            </button>
          </form>
          <div class="dorpdown">
            <div class="cw-icon">
              <i class="ci-left"></i>
              <i class="ci-right"></i>
              <i class="ci-count" id="shopping-amount">{{ cartCount }}</i>
              <router-link to="/shopcart">我的购物车</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data () {
    return {
      keyword: '',
    }
  },
  mounted () {
    this.$store.dispatch('shopCart/getCartCount')
    // 监听 search 组件发出的 clear 事件，一旦触发就清空关键词
    this.$bus.$on('clear', () => {
      this.keyword = ''
    })
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('shopCart', ['cartCount']),
    name () {
      return this.userInfo.nickname || this.userInfo.mobile
    },
  },
  methods: {
    goSearch () {
      if (this.keyword.trim()) {
        const location = {
          name: 'search',
          params: {
            keyword: this.keyword.trim() || undefined,
          },
        }

        // 判断是否有 query 参数，如果有则带进去
        if (this.$route.query) {
          location.query = this.$route.query
        }

        this.$router.push(location)
      }
    },
    async handleLogout () {
      try {
        await this.$store.dispatch('user/getLogout')
        this.$router.push('/login')
      } catch (error) {
        alert(error.message)
      }
    },
  },
}
</script>

<style lang="less">
.header {
  background: #fff;

  &>.top {
    background-color: #eaeaea;
    height: 30px;
    line-height: 30px;

    .container {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;

      .user {
        cursor: pointer;
      }

      .register {
        border-left: 1px solid #b3aeae;
        padding: 0 5px;
        margin-left: 5px;
      }

      .loginList {
        float: left;

        p {
          float: left;
          margin-right: 10px;
        }
      }

      .typeList {
        float: right;


        >a {
          padding: 0 10px;

          // & + a {
          //   border-left: 1px solid #b3aeae;
          // }

          // &+a:not(:last-child) {
          //   border-right: 1px solid #b3aeae;
          // }
        }
      }
    }
  }

  &>.bottom {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;

    .logoArea {
      float: left;

      .logo {
        img {
          width: 175px;
          margin: 25px 45px;
        }
      }
    }

    .searchArea {
      // float: right;
      margin-top: 35px;
      display: flex;
      align-items: center;

      .nav {
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;

        li {
          margin: 0 10px;

          a {
            color: #333;
            font-size: 16px;
            text-decoration: none;

            &.active {
              color: red;
            }

            &:hover {
              color: red;
            }
          }
        }
      }

      .search {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
      }

      .dorpdown {
        margin-left: 20px;
        position: relative;

        .ci-count {
          position: absolute;
          top: 1px;
          left: 20px;
          right: auto;
          display: inline-block;
          padding: 1px 3px;
          font-size: 12px;
          line-height: 12px;
          color: #fff;
          background-color: #e1251b;
          border-radius: 7px;
          min-width: 12px;
          text-align: center;
        }
      }

      .cw-icon {
        position: relative;
        width: 130px;
        height: 32px;
        background-color: #fff;
        text-align: center;
        line-height: 32px;
        border: 1px solid #e3e4e5;
      }

      .searchForm {
        overflow: hidden;

        input {
          box-sizing: border-box;
          width: 490px;
          height: 32px;
          padding: 0px 4px;
          border: 2px solid #ea4a36;
          float: left;

          &:focus {
            outline: none;
          }
        }

        button {
          height: 32px;
          width: 68px;
          background-color: #ea4a36;
          border: none;
          color: #fff;
          float: left;
          cursor: pointer;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
</style>
