import { getItemDetail, postChangeItem } from '@/api'
import { getUUID } from '@/utils'

export default {
  namespaced: true,
  state: {
    itemDetail: {
      categoryView: {
        category1Id: null,
        category1Name: '',
        category2Id: null,
        category2Name: '',
        category3Id: null,
        category3Name: '',
        id: null,
      },
      skuList: {
        skuImageList: [{ pic: '' }],
      },
      spuSaleAttrList: [],
      valuesSkuJson: undefined,
      price: null,
    },
    // 游客临时身份
    uuid_token: getUUID(),
    skuInfo: {},
  },
  mutations: {
    ITEMDETAIL(state, itemDetail) {
      state.itemDetail = itemDetail
    },
    SKUINFO(state, skuInfo) {
      state.skuInfo = skuInfo
    },
  },
  actions: {
    async getItemDetail({ commit }, params) {
      const res = await getItemDetail(params)
      if (res.code === 0) {
        commit('ITEMDETAIL', res.data)
        return res.data
      }
      return Promise.reject(new Error('fail'))
    },
    async postAddToCart(_, params) {
      const res = await postChangeItem(params)
      if (res.code === 0) {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
  },
  getters: {
    categoryView(state) {
      return state.itemDetail.categoryView
    },
    spuSaleAttrList(state) {
      return state.itemDetail.spuSaleAttrList
    },
    valuesSkuJson(state) {
      return state.itemDetail.valuesSkuJson
    },
    price(state) {
      return state.itemDetail.price
    },
    skuInfo(state) {
      return {
        ...this.itemDetail.skuList[0],
        ...state.skuInfo,
      }
    },
  },
}
