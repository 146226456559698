import {
  addUserAddress,
  getAddressList,
  getAddressDetail,
  setDefaultAddress,
  updateAddress,
  deleteAddress,
} from '@/api'

import AddressApi from '@/api/member/address'

export default {
  namespaced: true,
  state: {
    addressList: [],
    addressDetail: {},
  },
  mutations: {
    ADDRESSLIST(state, addressList) {
      state.addressList = addressList
    },
    ADDRESSDETAIL(state, addressDetail) {
      state.addressDetail = addressDetail
    },
  },
  actions: {
    async getAddressList({ commit }) {
      const res = await AddressApi.getAddressList()
      if (res.code === 0) {
        commit('ADDRESSLIST', res.data)
      }
    },
    async addAddress({ commit }, params) {
      const res = await AddressApi.addAddress(params)
      if (res.code === 0) {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async getAddressDetail({ commit }, id) {
      const res = await AddressApi.getAddress(id)
      if (res.code === 0) {
        commit('ADDRESSDETAIL', res.data)
        return Promise.resolve(res.data)
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async setDefaultAddress(_, id) {
      const res = await setDefaultAddress(id)
      if (res.code === 0) {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async updateAddress(_, params) {
      const res = await AddressApi.updateAddress(params)
      if (res.code === 0) {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async deleteAddress(_, id) {
      const res = await AddressApi.deleteAddress(id)
      if (res.code === 0) {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
  },
  getters: {},
}
