<template>
  <div class="app">
    <Header />
    <router-view class="views"></router-view>
    <Footer v-if="this.$route.meta.isFooterShow" />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  components: { Header, Footer },
  mounted () {
    // 通知 vuex 发请求,获取数据,存储于 store 中
    this.$store.dispatch('home/categoryList')
  },
}
</script>

<style lang="scss" scoped>
.app {
  background: #f4f4f4;
}

.views {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
}
</style>
