import axios from 'axios'
import nprogress from 'nprogress'
import store from '@/store'
import Vue from 'vue'
import 'nprogress/nprogress.css'
import { getToken, getRefreshToken, setToken, setRefreshToken } from '@/utils'
import { refreshToken as refreshTokenAPI } from '@/api'
import router from '@/router'
// nprogress： start 代表进度条开始， done 代表进度条结束
// 我们在请求发起时调用 start，在响应接收时，调用 done
// 引用进度条样式

let vm = new Vue()

// 利用 axios 对象的方法 create, 去创建一个 axios 实例
// request 就是 axios，只不过加了一些自定义配置
const requests = axios.create({
  // 基础 URL
  baseURL: 'http://api.bangbangmall.net/app-api',
  // baseURL: '/mock',
  // 超时时间 5s
  timeout: 5000,
})

// 请求拦截器：在发请求之前，做一些事情
requests.interceptors.request.use((config) => {
  // config: 配置对象，对象里 headers 请求头很重要
  // store.state.detail.uuid_token &&
  //   (config.headers.userTempId = store.state.detail.uuid_token)
  const token = getToken()
  token && (config.headers['Authorization'] = token)
  config.headers['Tenant-Id'] = 1
  nprogress.start()
  return config
})

// 响应拦截器
requests.interceptors.response.use(
  async (res) => {
    // 成功的回调函数
    nprogress.done()
    const { code, msg } = res.data
    switch (code) {
      case 0:
        break
      case 401:
        // vm.$notify.error({
        //   title: '错误',
        //   message: '登录信息失效，请重新登录',
        // })
        return refreshToken(res.config)
      // await store.dispatch('user/getLogout')
      // router.push('/login')
      default:
        vm.$notify.error({
          title: '错误',
          message: `${msg}`,
        })
        break
    }
    return res.data
  },
  () => {
    // 失败的回调函数
    nprogress.done()
    vm.$notify.error({
      title: '错误',
      message: '网络错误',
    })
    return Promise.reject(new Error('failed'))
  }
)

// Axios 无感知刷新令牌，参考 https://www.dashingdog.cn/article/11 与 https://segmentfault.com/a/1190000020210980 实现
let requestList = [] // 请求队列
let isRefreshToken = false // 是否正在刷新中
const refreshToken = async (config) => {
  // 如果当前已经是 refresh-token 的 URL 地址，并且还是 401 错误，说明是刷新令牌失败了，直接返回 Promise.reject(error)
  if (config.url.indexOf('/member/auth/refresh-token') >= 0) {
    return Promise.reject('error')
  }

  // 如果未认证，并且未进行刷新令牌，说明可能是访问令牌过期了
  if (!isRefreshToken) {
    isRefreshToken = true
    // 1. 如果获取不到刷新令牌，则只能执行登出操作
    const refreshToken = getRefreshToken()
    if (!refreshToken) {
      return handleAuthorized()
    }
    // 2. 进行刷新访问令牌
    try {
      const refreshTokenResult = await refreshTokenAPI(refreshToken)
      if (refreshTokenResult.code !== 0) {
        // 如果刷新不成功，直接抛出 e 触发 2.2 的逻辑
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('刷新令牌失败')
      }
      // 2.1 刷新成功，则回放队列的请求 + 当前请求
      const { accessToken, refreshToken: newRefreshToken } =
        refreshTokenResult.data
      setToken(accessToken)
      setRefreshToken(newRefreshToken)
      config.header['Authorization'] = accessToken
      requestList.forEach((cb) => {
        cb()
      })
      requestList = []
      return request(config)
    } catch (e) {
      // 为什么需要 catch 异常呢？刷新失败时，请求因为 Promise.reject 触发异常。
      // 2.2 刷新失败，只回放队列的请求
      requestList.forEach((cb) => {
        cb()
      })
      // 提示是否要登出。即不回放当前请求！不然会形成递归
      return handleAuthorized()
    } finally {
      requestList = []
      isRefreshToken = false
    }
  } else {
    // 添加到队列，等待刷新获取到新的令牌
    return new Promise((resolve) => {
      requestList.push(() => {
        config.header['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        resolve(request(config))
      })
    })
  }
}

const handleAuthorized = async () => {
  // 登录超时
  const isLogin = !!getToken()
  vm.$notify.error({
    title: '错误',
    message: isLogin ? '您的登陆已过期' : '请先登录',
  })

  setTimeout(() => {
    router.push('/login')
  }, 1000)
  // 登出
  await store.dispatch('user/getLogout')
  return Promise.reject({
    code: 401,
    msg: isLogin ? '您的登陆已过期' : '请先登录',
  })
}

export default requests
