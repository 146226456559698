import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import home from './home'
import search from './search'
import detail from './detail'
import shopCart from './shopCart'
import register from './register'
import user from './user'
import trade from './trade'
import mine from './mine'
import order from './order'
import collect from './collect'
import address from './address'
import invoice from './invoice'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    home,
    search,
    detail,
    shopCart,
    register,
    user,
    trade,
    mine,
    order,
    collect,
    address,
    invoice,
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
})
