import { postAddCollect, getCollectList } from '@/api'

export default {
  namespaced: true,
  state: {
    captcha: '',
    collectList: [],
  },
  mutations: {
    COLLECTLIST(state, collectList) {
      state.collectList = collectList
    },
  },
  actions: {
    async postAddCollect(_, params) {
      const res = await postAddCollect(params)
      if (res.code === '00000') {
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
    async getCollectList({ commit }, params) {
      const res = await getCollectList(params)
      if (res.code === '00000') {
        commit('COLLECTLIST', res.data)
        return 'ok'
      } else {
        return Promise.reject(new Error('fail'))
      }
    },
  },
  getters: {},
}
