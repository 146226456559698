import requests from './ajax'
// import mockRequests from './mockAjax'

// mock 三级联动的接口
// export const getMockCategoryList = () => mockRequests.get('/category')
// 分类标签
export const getCategoryList = (params) =>
  requests({
    url: '/product/category/list',
    method: 'get',
    data: params,
  })

// 分组接口
export const getTagList = (params) =>
  requests({
    url: '/prod/tag/prodTagList',
    method: 'get',
    data: params,
  })

// 首页头部 banner
export const getBannerList = (params) =>
  requests({
    url: '/promotion/banner/list',
    method: 'get',
    params,
  })
// mock floor 接口
// export const getFloorList = () => mockRequests.get('/floor')

// 产品搜索列表
// params 默认参数，应当是一个空对象，否则报错
// 当参数是空对象时，返回所有 items
export const getSearchList = (params) =>
  requests({
    url: '/product/spu/page',
    method: 'get',
    params,
  })

// 商品详情
export const getItemDetail = (prodId) =>
  requests({
    url: `/product/spu/get-detail?id=${prodId}`,
    method: 'get',
  })

// 添加到购物车或对购物车商品数量进行修改
export const postChangeItem = (params) =>
  requests({
    url: `/trade/cart/add`,
    method: 'post',
    data: params,
  })

// 购物车商品数量
export const getCartCount = () =>
  requests({
    url: `/trade/cart/get-count`,
    method: 'get',
  })

// 购物车列表
export const getCartList = () =>
  requests({
    url: `/trade/cart/list`,
    method: 'get',
  })

// 删除购物车物品
export const deleteCart = (ids) =>
  requests({
    url: `/p/shopCart/deleteItem`,
    method: 'delete',
    data: ids,
  })

// 切换商品选中状态
export const updateCartSelected = (data) =>
  requests({
    url: '/trade/cart/update-selected',
    method: 'put',
    data,
  })

// 获取购物车选中项目总计
export const getTotalPay = (arr) =>
  requests({
    url: `/p/shopCart/totalPay`,
    method: 'post',
    data: arr,
  })
// 获取验证码
export const getSendCode = (params) =>
  requests({
    url: `/member/auth/send-sms-code`,
    method: 'post',
    data: params,
  })

// 注册
export const postRegister = (params) =>
  requests({
    url: `/user/register`,
    method: 'post',
    data: params,
  })

// 登录
export const postLogin = (params) =>
  requests({
    url: `/login`,
    method: 'post',
    data: params,
  })
// 手机号验证码登录
export const postSmsLogin = (params) =>
  requests({
    url: '/member/auth/sms-login',
    method: 'post',
    data: params,
  })
// 用户信息
export const getUserInfo = () =>
  requests({
    url: `/member/user/get`,
    method: 'get',
  })

// 退出登录
export const getLogout = () =>
  requests({
    url: `/member/auth/logout`,
    method: 'post',
  })

// 刷新令牌
export const refreshToken = (refreshToken) =>
  requests({
    url: '/member/auth/refresh-token',
    method: 'POST',
    params: {
      refreshToken,
    },
  })

// 交易页信息
export const getTradeInfo = () =>
  requests({
    url: `/order/auth/trade`,
    method: 'get',
  })

// 首页公告列表
export const getNoticeList = (params) =>
  requests({
    url: '/shop/notice/noticeList',
    method: 'get',
    params,
  })

// 公告详情
export const getNoticeInfo = (id) =>
  requests({
    url: `/shop/notice/info/${id}`,
    method: 'get',
  })

// 首页所有标签商品列表
export const getTagProdList = () =>
  requests({
    url: '/prod/tagProdList',
    method: 'get',
  })

// 结算，生成订单
export const postOrderConfirm = (data) => {
  console.log('data', data)
  const data2 = {
    ...data,
  }
  // 移除多余字段
  if (!(data.couponId > 0)) {
    delete data2.couponId
  }
  if (!(data.addressId > 0)) {
    delete data2.addressId
  }
  if (!(data.combinationActivityId > 0)) {
    delete data2.combinationActivityId
  }
  if (!(data.combinationHeadId > 0)) {
    delete data2.combinationHeadId
  }
  if (!(data.seckillActivityId > 0)) {
    delete data2.seckillActivityId
  }
  // 解决 SpringMVC 接受 List<Item> 参数的问题
  delete data2.items
  for (let i = 0; i < data.items.length; i++) {
    data2[encodeURIComponent('items[' + i + '' + '].skuId')] =
      data.items[i].skuId + ''
    data2[encodeURIComponent('items[' + i + '' + '].count')] =
      data.items[i].count + ''
    if (data.items[i].cartId) {
      data2[encodeURIComponent('items[' + i + '' + '].cartId')] =
        data.items[i].cartId + ''
    }
  }
  const queryString = Object.keys(data2)
    .map((key) => key + '=' + data2[key])
    .join('&')

  return requests({
    url: `/trade/order/settlement?${queryString}`,
    method: 'get',
  })
}

// 更新结算中订单
export const postOrderUpdate = (params) =>
  requests({
    url: `/p/order/update/confirm`,
    method: 'post',
    data: params,
  })

// 提交订单
export const postOrderSubmit = (params) =>
  requests({
    url: `/trade/order/create`,
    method: 'post',
    data: params,
  })

// 获取省市区
export const getArea = (pid) =>
  requests({
    url: `/p/area/listByPid?pid=${pid}`,
    method: 'get',
  })

// 订单收件人地址
export const getAddressList = () =>
  requests({
    url: `/member/address/list`,
    method: 'get',
  })

// 新增用户地址
export const addUserAddress = (params) =>
  requests({
    url: `/member/address/create`,
    method: 'post',
    data: params,
  })

// 用户地址详情
export const getAddressDetail = (addrId) =>
  requests({
    url: `/p/address/addrInfo/${addrId}`,
    method: 'get',
  })

// 设为默认地址
export const setDefaultAddress = (addrId) =>
  requests({
    url: `/p/address/defaultAddr/${addrId}`,
    method: 'put',
  })
// 更新用户地址
export const updateAddress = (params) =>
  requests({
    url: `/p/address/updateAddr`,
    method: 'put',
    data: params,
  })
// 删除地址
export const deleteAddress = (addrId) =>
  requests({
    url: `/p/address/deleteAddr/${addrId}`,
    method: 'delete',
  })

// 订单列表
export const getOrderList = (params) =>
  requests({
    url: `/p/myOrder/myOrder`,
    method: 'get',
    params,
  })

// 添加取消收藏
export const postAddCollect = (params) =>
  requests({
    url: `/p/user/collection/addOrCancel`,
    method: 'post',
    params,
  })

// 收藏列表
export const getCollectList = (params) =>
  requests({
    url: `/p/user/collection/prods`,
    method: 'get',
    params,
  })
// 发票信息
export const getInvoice = () =>
  requests({
    url: `/p/invoice/addr`,
    method: 'get',
  })
// 更新发票信息
export const updateInvoice = (params) =>
  requests({
    url: `/p/invoice/addr`,
    method: 'put',
    data: params,
  })
// 新增发票信息
export const addInvoice = (params) =>
  requests({
    url: `/p/invoice/addr`,
    method: 'post',
    data: params,
  })
// 根据分类id获取商品列表 /prod/pageProd
export const getPageProdByCategory = (params) =>
  requests({
    url: `/product/spu/page`,
    method: 'get',
    params,
  })
// 根据分组id获取商品列表 /prod/prodListByTagId
export const getProdListByTagId = (params) =>
  requests({
    url: `/prod/prodListByTagId`,
    method: 'get',
    params,
  })
// 待开发票列表 /p/invoice/order
export const getInvoiceOrder = (params) =>
  requests({
    url: `/p/invoice/order`,
    method: 'get',
    params,
  })
// 申请发票 /p/invoice
export const postApplyInvoice = (params) =>
  requests({
    url: `/p/invoice`,
    method: 'post',
    params,
  })
// 订单详情 /p/myOrder/orderDetail
export const getOrderDetail = (params) =>
  requests({
    url: `/p/myOrder/orderDetail`,
    method: 'get',
    params,
  })

// 根据商品匹配优惠券
export const getCouponByProd = (params) =>
  requests({
    url: `/promotion/coupon/match-list`,
    method: 'get',
    params,
  })
